import { Turbo } from '@hotwired/turbo-rails'
import TurboPower from 'turbo_power'

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import NestedForm from 'stimulus-rails-nested-form'
import Popover from '@stimulus-components/popover'
import Clipboard from '@stimulus-components/clipboard'
import 'trix'
import 'trix/dist/trix.css'
import '@rails/actiontext'
import '../styles/actiontext.css'

TurboPower.initialize(Turbo.StreamActions)

declare global {
  interface Window {
    Stimulus: Application
  }
}

const application = Application.start()
application.register('nested-form', NestedForm)
application.register('popover', Popover)
application.register('clipboard', Clipboard)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

const controllers = import.meta.glob('../**/*_controller.ts', { eager: true })
registerControllers(application, controllers)

export { application }
